<template>
    <v-row justify="start">
      <v-dialog v-model="dialogDelPanduan" persistent max-width="400px">
        <v-card class="rounded-max hide_overflow" flat>
          <div class="closer d-flex justify-end pa-1">
            <v-btn icon small @click="$emit('close')">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
          <div
            class="d-flex flex-column align-center justify-center"
            style="height: 230px"
          >
            <div>
              <div class="px-5">
                <p class="small_txt color_txt text-center">
                  Anda yakin ingin menghapus pertanyaan ini?
                </p>
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  class="text-capitalize py-6 mr-4"
                  @click="deleteItem"
                  dark
                  color="#00b4cc"
                  width="100px"
                  depressed
                  >Ya</v-btn
                >
                <v-btn
                  class="text-capitalize py-6"
                  @click="$emit('close')"
                  outlined
                  color="#00b4cc"
                  width="100px"
                  depressed
                  >Batal</v-btn
                >
              </div>
            </div>
            <div style="height: 20px"></div>
            <v-overlay
              :absolute="true"
              color="white"
              opacity="1"
              :value="loading"
            >
              <v-progress-circular
                indeterminate
                class="mr-2"
                color="#00b4cc"
              ></v-progress-circular>
              <b class="color_default">Loading...</b>
            </v-overlay>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import Swal from "sweetalert2";
  import { mapState } from "vuex";
  // import Swal from "sweetalert2";
  
  export default {
    name: "deletePanduan",
    props: ["dialogDelPanduan", "body"],
    computed: {
      ...mapState({
        err: (state) => state.auth.errMsg,
        solve: (state) => state.auth.response,
      }),
    },
    data() {
      return {
        role: "",
        loading: false,
      };
    },
    methods: {
      deleteItem() {
        this.loading = true;
        this.$store
          .dispatch("tentang/deletePanduan", this.body.id)
          .then(() => {
            this.loading = false;
            this.$emit('close')
            this.$emit('refetch')
            Swal.fire({
              icon: "success",
              title: "Data Terhapus!",
              showConfirmButton: false,
              position: "top-end",
              timer: 3000,
              toast: true,
              customClass: {
                title: "family",
              },
            });
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      },
    },
  };
  </script>
  
  <style scoped>
  </style>