<template>
  <div class="container_about">
    <div v-if="content" class="pa-5">
      <div class="d-flex pa-2 mb-3 align-center">
        <blue-circle class="mr-3" />
        <h3 class="color_default">Konfigurasi Panduan</h3>
      </div>

      <v-card class="rounded-max custom_card pa-5" style="margin-top: 20px">
        <h2 class="color_txt mb-3">Panduan</h2>
        <v-card
          outlined
          class="rounded-lg pa-3 mb-3"
          v-for="(panduan, idx) in content[5]?.panduan"
          :key="`panduan-${idx}`"
        >
          <div>
            <div class="d-flex mb-3">
              <v-icon class="mr-3" color="#61C4D7"
                >mdi-plus-circle-outline</v-icon
              >
              <p class="ma-0 pa-0 color_txt">{{ panduan?.pertanyaan }}</p>
            </div>
            <div class="bg_main pa-2 rounded">
              <div v-html="panduan?.jawaban"></div>
            </div>
          </div>
          <div class="d-flex justify-end mt-3">
            <v-btn
              rounded
              dark
              color="#59B5CC"
              @click="updatePanduan(panduan)"
              class="pa-5 mr-3"
              depressed
              ><v-icon>mdi-square-edit-outline</v-icon></v-btn
            >
            <v-btn
              rounded
              dark
              outlined
              color="#59B5CC"
              @click="delPanduan(panduan)"
              class="pa-5"
              depressed
              ><v-icon>mdi-trash-can-outline</v-icon></v-btn
            >
          </div>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            dark
            outlined
            color="#59B5CC"
            @click="dialogCreatePanduan = true"
            class="pa-5 text-capitalize"
            depressed
            ><v-icon>mdi-plus</v-icon> Tambah Pertanyaan</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
    <div class="pa-3">
      <delete-panduan
        v-bind:dialogDelPanduan="dialogDelPanduan"
        @close="dialogDelPanduan = false"
        v-bind:body="panduanItem"
        @refetch="getData"
      />
      <create-panduan
        v-bind:dialogCreatePanduan="dialogCreatePanduan"
        @close="dialogCreatePanduan = false"
        @refetch="getData"
      />
      <update-panduan
        v-bind:dialogUpdatePanduan="dialogUpdatePanduan"
        v-bind:body="panduanItem"
        @close="dialogUpdatePanduan = false"
        @refetch="getData"
      />
    </div>
    <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#00b4cc"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import BlueCircle from "../../../components/General/BlueCircle";
import CreatePanduan from "./createPanduan.vue";
import UpdatePanduan from "./updatePanduan.vue";
import DeletePanduan from "./deletePanduan.vue";

export default {
  components: { BlueCircle, CreatePanduan, UpdatePanduan, DeletePanduan },
  name: "PanduanConf",
  computed: {
    ...mapState({
      content: (state) => state.tentang.tentang,
    }),
  },
  data() {
    return {
      loading: false,
      dialogAlert: false,
      customToolbar: [],
      dialogCreatePanduan: false,
      dialogUpdatePanduan: false,
      dialogDelPanduan: false,
      panduanItem: null,
      panduan: null,
      status: {
        item1: false,
        item2: false,
        item3: false,
      },
      mutateSeries: {
        status: true,
        code: "00",
        message: "Success",
        endpoint: "api/grafik/limbah-fasyankes/pemilihan",
        data: {
          total: 6,
          items: [
            {
              name: "Dilakukan pemilahan",
              value: 3,
            },
            {
              name: "Dilakukan pemilahan",
              value: 3,
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    parseJawaban(jawaban) {
      if (!jawaban) {
        return "";
      }

      let doc = new DOMParser().parseFromString(jawaban, "text/html");
      let img = doc.querySelector("img");
      if (img) {
        let base64String = img.src.split(",")[1];
        let blob = this.base64ToBlob(base64String, "image/jpeg");
        let blobUrl = URL.createObjectURL(blob);
        img.src = blobUrl;
      }
      return doc.body.innerHTML;
    },
    base64ToBlob(base64, mimeType) {
      let bytes = atob(base64);
      let arrayBuffer = new ArrayBuffer(bytes.length);
      let uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < bytes.length; i++) {
        uint8Array[i] = bytes.charCodeAt(i);
      }
      return new Blob([arrayBuffer], { type: mimeType });
    },
    delPanduan(item) {
      this.panduanItem = item;
      this.dialogDelPanduan = true;
    },
    updatePanduan(item) {
      this.panduanItem = item;
      this.dialogUpdatePanduan = true;
    },
    updateData(id, idx, status) {
      let body = {
        judul: this.content[idx].judul,
        konten: this.content[idx].konten,
      };
      let data = {
        id: id,
        body: body,
      };
      this.$store.dispatch("tentang/updateTentang", data).then(() => {
        this.status[status] = false;
        this.Swal("success", "Success");
      });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
    getData() {
      this.loading = true;
      this.$store.dispatch("tentang/getTentang").then((data) => {
        console.log(this.content[5]);
        this.loading = false;
      });
    },
  },
};
</script>

<style>
.app_name {
  font-size: 60px;
}
.container_about {
  padding: 20px;
}
.ql-toolbar {
  border-radius: 8px 8px 0 0 !important;
}
.ql-container {
  border-radius: 0 0 8px 8px !important;
}
.title_input {
  border: 2px #e5edee solid;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}
.title_input:focus {
  outline: none;
}
</style>
